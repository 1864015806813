import React, { useState } from 'react'
import styled from 'styled-components'
import { Container } from 'reactstrap'
import { Link } from 'gatsby'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import { USER_REPAIR_ITEMS } from '~queries/repairItems'

import { Lead } from '~components/Typography'
import Main from '~components/layouts/Main'

import scssVars from '~components/scss-variables'
import Layout from '~components/Layout'
import HeroSection from '~components/layouts/marketing/HeroSection'
import Instructions from '~components/EstimatesDelivery/Instructions'
import MailInForm from '~components/EstimatesDelivery/MailInForm/'
import DropOffForm from '~components/EstimatesDelivery/DropOffForm/'
import PickUpForm from '~components/EstimatesDelivery/PickUpForm/'
import { PrivateRoute } from '~components/Routes'

import ZipCodeContext from '~components/EstimatesDelivery/ZipCodeContext'
import DeliveryOptionsDesktop from './DeliveryOptions/Desktop'
import DeliveryOptionsMobile from './DeliveryOptions/Mobile'

const Hr = styled.hr`
  border-top: 1px solid ${scssVars.brown};
`

const Footer = styled.p`
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
`

const None = styled.h4`
  text-align: center;
  border: 2px solid ${scssVars.brightOrange};
  padding: 20px;
  margin: 0 auto;
`

function EstimatesDelivery () {
  const [zipCode, setZipCode] = useState('')
  const contextValue = { zipCode, setZipCode }
  const [option, selectOption] = useState('none')
  const { data: { myRepairItems = [] } = {} } = useQuery(USER_REPAIR_ITEMS)

  const heroBanner = 'https://leatherspa.imgix.net/108385325-8d748e00-71d9-11eb-9d09-266e91b87482.jpg'
  return (
    <PrivateRoute>
      <Layout>
        <Main>
          <ZipCodeContext.Provider value={contextValue}>
            <HeroSection title={'Final Steps'}
              breadcrumb={{ title: 'My Account', path: '/account' }}
              mobileImg={`${heroBanner}?w=1000`}
              desktopImg={`${heroBanner}?w=2000`}>
              <>
                <Lead>Send Us Your Items:</Lead>
                <p>Choose the most convenient shipping mode for you and select the estimates you want to send us. You can mail-in your items, schedule a pick-up or bring in your items directly to a Leather Spa store.</p>
                <p>
                  <span>
                    <FontAwesomeIcon className="text-primary" icon={faExclamationCircle} />&nbsp;
                    If you don&nbsp;t see your estimates below after you selected a shipping method, please make sure to refresh the page.
                  </span>
                </p>
              </>
            </HeroSection>
            <div className="d-lg-none">
              <DeliveryOptionsMobile option={option} selectOption={selectOption} />
            </div>
            <div className="d-none d-lg-block">
              <DeliveryOptionsDesktop option={option} selectOption={selectOption} />
            </div>
            <Instructions option={option} />
            {myRepairItems.length > 0 ? (
              <>
                {option === 'mailIn' && <MailInForm estimates={myRepairItems} />}
                {(option === 'pickUp.success' || option === 'pickUp.success.closedForm') && <PickUpForm estimates={myRepairItems} />}
                {option === 'dropOff' && <DropOffForm estimates={myRepairItems} />}
              </>
            ) : (
              <Container className="mt-5 text-center">
                <None>No Estimates Yet</None>
                <Link className="my-5 w-auto px-5 btn btn-primary" to="/repair-wizard">
                  Create Your First Estimate
                </Link>
              </Container>
            )}

          </ZipCodeContext.Provider>
          <Container>
            <Hr />
            <Footer>
              Need any help, or have questions? Call <a href="tel:+18779693550">877‑969‑3550</a> or email us at <a href="mailto:repairs@leatherspa.com">repairs@leatherspa.com</a>
            </Footer>
          </Container>
        </Main>
      </Layout>
    </PrivateRoute>
  )
}

export default EstimatesDelivery
