import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import scssVars from '~components/scss-variables'
import { Lead } from '~components/Typography'
import { Picture } from '~components/Services/Intro'
import PickUpAvailabilityCheck from '~components/EstimatesDelivery/PickUpAvailabilityCheck'

const OptionCol = styled(Col)`
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
`

const PictureWrapper = styled.div`
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    position: relative;
    z-index: 0;
    width: auto;
    display: inline-block;
    img {
      max-width: 100%;
      max-height: 225px;
    }
    &:after {
      content: '';
      border: 4px solid transparent;
      transition: border 0.3s;
      border-radius: 100%;
      z-index: 0;
      display: block;
      width: 105%;
      height: 105%;
      position: absolute;
      top: -2.5%;
      left: -2.5%;
    }
    svg {
      color: ${scssVars.brightOrange};
      position: absolute;
      z-index: 1;
      opacity: 0;
      right: 0;
      font-size: 50px;
      transition: opacity 0.3s;
      top: 5px;
    }
    &.selected {
      &:after {
        border-color: ${scssVars.brightOrange};
      }
      svg {
        opacity: 1;
      }
    }
  }
`

const Triangle = styled.div`
  position: absolute;
  width: 57px;
  height: 57px;
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  background-color: ${scssVars.lightGray};
  left: calc(50% - 28.5px);
  bottom: -30px;
`

const Desktop = ({ option, selectOption }) => {
  const OptionButton = ({ select, disabled, ariaExpanded }) => (
    <div>
      <button className="w-auto px-5 btn btn-primary" onClick={() => selectOption(select)} disabled={option === disabled} aria-expanded={ariaExpanded}>
        Select
      </button>
    </div>
  )

  OptionButton.propTypes = {
    select: PropTypes.string.isRequired,
    disabled: PropTypes.string.isRequired,
    ariaExpanded: PropTypes.bool.isRequired
  }

  return (
    <Container>
      <h3 className="mt-5">Choose a Shipping Mode:</h3>
      <Row className="text-center mt-5 mx-0">
        <OptionCol xs={4} className="position-relative pb-5">
          <div>
            <PictureWrapper className={`mb-4 ${option === 'mailIn' ? 'selected' : ''}`}>
              <Picture
                mobileSrc={'https://leatherspa.imgix.net/LS_Repair-Wizard_Box.jpg?fit=scale&w=768'}
                src={'https://leatherspa.imgix.net/LS_Repair-Wizard_Box.jpg?fit=scale&w=768'}
              />
              <FontAwesomeIcon icon={faCheck} />
            </PictureWrapper>
          </div>
          <Lead>Mail-In For Repair</Lead>
          <p>We accept mail-in repairs worldwide and even from our own backyard. Simply send us your items if you can’t bring them in-store.</p>
          <OptionButton select="mailIn" disabled="mailIn" ariaExpanded={option === 'mailIn'} />
          {option === 'mailIn' && <Triangle />}
        </OptionCol>
        <OptionCol xs={4} className="position-relative pb-5">
          {['none', 'pickUp.success.closedForm', 'dropOff', 'mailIn'].includes(option) && <>
            <div>
              <PictureWrapper className={`mb-4 ${option === 'pickUp.success.closedForm' ? 'selected' : ''}`}>
                <Picture
                  mobileSrc={'https://leatherspa.imgix.net/LS_Repair-Wizard_Van.jpg?fit=scale&w=768'}
                  src={'https://leatherspa.imgix.net/LS_Repair-Wizard_Van.jpg?fit=scale&w=768'}
                />
                <FontAwesomeIcon icon={faCheck} />
              </PictureWrapper>
            </div>
            <Lead>Schedule A Pick-Up</Lead>
            <p>
              We offer pick-up services to most of Manhattan, much of Brooklyn, and Long Island City, Queens.
            </p>
            <OptionButton select="pickUp.form" disabled="pickUp.success.closedForm" ariaExpanded={['pickUp.form', 'pickUp.error', 'pickUp.success'].includes(option)} />
          </>}
          {['pickUp.form', 'pickUp.error', 'pickUp.success'].includes(option) && <PickUpAvailabilityCheck selectOption={selectOption} option={option} />}
          {(option === 'pickUp.success' || option === 'pickUp.success.closedForm') && <Triangle />}
        </OptionCol>
        <OptionCol xs={4} className="position-relative pb-5">
          <div>
            <PictureWrapper className={`mb-4 ${option === 'dropOff' ? 'selected' : ''}`}>
              <Picture
                mobileSrc={'https://leatherspa.imgix.net/LS_Repair-Wizard_Map.jpg?fit=scale&w=768'}
                src={'https://leatherspa.imgix.net/LS_Repair-Wizard_Map.jpg?fit=scale&w=768'}
              />
              <FontAwesomeIcon icon={faCheck} />
            </PictureWrapper>
          </div>
          <Lead>Drop-Off For Repair</Lead>
          <p>Experience our expertise in-person at one of our breathtaking Manhattan locations.</p>
          <OptionButton select="dropOff" disabled="dropOff" ariaExpanded={option === 'dropOff'} />
          {option === 'dropOff' && <Triangle />}
        </OptionCol>
      </Row>
    </Container>
  )
}

Desktop.propTypes = {
  option: PropTypes.string.isRequired,
  selectOption: PropTypes.func.isRequired
}

export default Desktop
