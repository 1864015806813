import React from 'react'
import PropType from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'

import scssVars from '~components/scss-variables'
import { Picture as DefaultPicture } from '../Services/Intro'
import mailIn from '../../../static/img/estimates-img/Mail-in-Instruction-Icon@2x.png'
import van from '../../../static/img/estimates-img/Pick-up-Instructions-Icon@2x.png'
import dropOff from '../../../static/img/estimates-img/Drop-off-Instructions-Icon@2x.png'

const Main = styled.div`
  background-color: ${scssVars.lightGray};
  position: relative;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    background-color: ${scssVars.white};
  }
`

const List = styled.ul`
  list-style: none;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.54px;
  padding-bottom: 50px;
  @media only screen and (max-width: ${scssVars.tabletMax}) {
    max-width: 95%;
    margin: 0 auto;
    padding-bottom: 0px;
  }
  li {
    span {
      font-weight: 700;
    }
    position: relative;
    counter-increment: step-counter;
    color: ${scssVars.darkBrown};
    margin-bottom: 9px;
    :before {
      position: absolute;
      display: inline-block;
      left: -30px;
      content: counter(step-counter) ". ";
      color: ${scssVars.brightOrange};
      font-weight: 800;
    }
  }
`

const Picture = styled(DefaultPicture)`
  width: 100%;
  height: auto;
  margin: 0 40px;
`

const Title = styled.h1`
  color: ${scssVars.darkBrown};
  font-weight: 300;
  padding: 50px 0 50px 0;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    text-align: center;
    flex-direction: column;
    padding: 20px 0 20px 0;
  }

  span {
    @media only screen and (max-width: ${scssVars.tabletMax}) {
      padding-right: 0;
      max-width: 90%;
      margin: 0 auto;
    }
  }

  hr {
    background-color: ${scssVars.darkBrown};
    height: 1px;
    flex-grow: 1;
    position: relative;
    top: 7px;

    @media only screen and (max-width: ${scssVars.tabletMax}) {
      top: 0;
      width: 40%;
      margin: 20px auto;
    }
  }
`

function Instructions ({ option }) {
  return !['none', 'pickUp', 'pickUp.form', 'pickUp.error'].includes(option)
    ? (<Main>
      <Title>
        <span>
          {option === 'mailIn' && 'Mail-In Service'}
          {option.substring(0, 14) === 'pickUp.success' && 'Pick-Up Service'}
          {option === 'dropOff' && 'Drop-Off Service'}
        </span>
        <hr />
      </Title>
      <Container>
        <Row>
          <Col lg={4} className="d-none d-lg-block">
            <div className="pr-5">
              {option === 'mailIn' && <Picture src={mailIn} mobileSrc={mailIn} />}
              {option.substring(0, 14) === 'pickUp.success' && <Picture src={van} mobileSrc={van} />}
              {option === 'dropOff' && <Picture src={dropOff} mobileSrc={dropOff} />}
            </div>
          </Col>
          <Col lg={4}>
            {option === 'mailIn' &&
            <List>
              <li>
                <span>Drop off at The UPS Store</span>
                <p>
                  Simply bring to any UPS Store within the continental US.
                  They will handle the packing, labeling, and shipping of your repair order.
                  We'll email you a drop off confirmation code and instructions.
                  Print out or show it on your smartphone to a store associate at The UPS Store.
                </p>
                <p>
                  Free return shipping is included with this option.
                  Includes package insurance up to $2,500.00 to and from Leather Spa.
                  For more information on this service, please check out our <a className="text-link" target="_blank" href="https://leatherspa.zendesk.com/hc/en-us/articles/360057155573-The-UPS-Store-Drop-Off-Program">help center</a> article.
                </p>
                <p>*Printer and packaging not required.</p>
              </li>
              <li>
                <span>
                  <span style={{ color: scssVars.orange }}>NEW!</span>&nbsp;
                  Create and print a UPS Shipping Label
                </span>
                <p>
                  Create and print a shipping label on our platform and use your own packaging.
                  Your shipping label will be emailed to you instantly.
                </p>
                <p>
                  Free return shipping is included with this option.
                  Includes package insurance up to $2,500.00 to and from Leather Spa.
                  For more information on this service, please check out our <a className="text-link" target="_blank" href="https://leatherspa.zendesk.com/hc/en-us">help center</a> article.
                </p>
                <p>*Printer and packaging required.</p>
              </li>
              <li>
                <span>Ship it myself</span>
                <p>Organize shipment yourself.</p>
                <p>You will use your own carrier, label, and packaging and pay for return shipping.</p>
              </li>
            </List>}
            {option.substring(0, 14) === 'pickUp.success' &&
            <List>
              <li>
                <span>Standard Pick-up Service</span>
                <p>No set time. Next business day pickup between 10 am and 7 pm.</p>
                <p>
                  Free return delivery is included with this option.
                  Includes package insurance up to $2,500.00 to and from Leather Spa.
                  For more information on this service, please check out our help center article.
                </p>
              </li>

              <li>
                <span>On-Demand Pick-up Service</span>
                <p>Schedule a pickup date and time that works for you. We will pick up your item(s) within the two-hour window you select.</p>
                <p>
                  Free return delivery is included with this option.
                  Includes package insurance up to $2,500.00 to and from Leather Spa.
                  For more information on this service, please check out our help center article.
                </p>
              </li>
            </List>}
            {option === 'dropOff' &&
            <div className="pl-3">
                <p>Experience our superb customer service and leather expertise in person at one of our multiple NYC locations. Bring in your shoes or accessory for a repair evaluation by a trained specialist.</p>
                <p><span style={{ color: scssVars.orange }}>NEW!</span> Skip the waiting time and try our Express Drop Off Service</p>
                <p>How it works:</p>
                <p>Simply start a repair order online and drop off your item(s) at any of our locations with the assigned repair order number and we will take care of the rest.</p>
                <Link to="/locations" target="_blank" style={{ marginTop: 15, fontStyle: 'italic', fontWeight: 600 }}>Find stores</Link>
            </div>}
          </Col>
        </Row>
      </Container>
    </Main>)
    : null
}

Instructions.propTypes = {
  option: PropType.string.isRequired
}

export default Instructions
