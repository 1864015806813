import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import scssVars from '~components/scss-variables'

const Main = styled.div`
  width: 100%;
  height: auto;
  background-color: ${scssVars.gray};
`

const Content = styled.div`
  width: 90%;
  max-width: 1280px;
  padding: 50px 0 50px 0;
  margin: auto;
  font-size: 16px !important;
  line-height: 1.3;

  h4 {
    font-size: 18px;
    font-weight: 600;
  }

  ul {
    margin: 0 0 15px 20px;
    padding: 0;

    li {
      span {
        display: inline-block;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        width: 145px;
      }
    }
  }

  a {
    font-family: inherit;
    line-height: 1;
  }
`

const Accesibility = styled.p`
  margin-top: 40px;

  svg, span {
    font-family: "Montserrat", sans-serif;
    color: ${scssVars.brightOrange};
    margin-right: 5px;
    font-weight: 700;
  }
`

const WhatsNextSection = ({ children }) => (
  <Main>
    <Content>
      <h4>What Happens Next</h4>
      {children}
      <Accesibility>
        <FontAwesomeIcon icon={faExclamationCircle} />
        <span>ACCESSIBILITY:</span>
        If you are having any difficulty using this page or service, please contact the Help Desk at <a href="mailto:info@leatherspa.com">info@leatherspa.com</a> or <a href="tel:+17183929220">(718) 392-9220</a>. Please identify the specific problems you have encountered and we will address the issue or assist you over the phone.
      </Accesibility>
    </Content>
  </Main>
)

WhatsNextSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]).isRequired
}

export default WhatsNextSection
