import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useFormikContext } from 'formik'

import scssVars from '~components/scss-variables'

import { Title, BlockError } from './Components'

import Estimate from './Estimate'

const Main = styled.ul`
  list-style: none;
  padding: 0;
  margin: 30px auto 60px auto;
  max-width: 1280px;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    margin: 0 auto 40px auto;
    max-width: 95%;
  }

  > div {
    margin-top: 20px;
  }
`

const Estimates = ({ estimates }) => {
  const { errors, touched } = useFormikContext()
  return (
    <>
      <Title className="pb-0 pb-lg-3">
        <span>Your Estimates</span>
        <hr />
      </Title>
      <div className="container px-0">
        <p className="text-center text-lg-left" style={{ fontSize: '19px' }}>
          Please select the Estimates that you are sending us.
          </p>
      </div>
      <Main>
        {estimates.map((estimate) => (
          <li key={estimate.id}>
            <Estimate estimate={estimate} />
          </li>
        ))}
        {errors &&
          errors.itemIds &&
          touched &&
          Object.values(touched).length > 0 && (
            <BlockError>{errors.itemIds}</BlockError>
          )}
      </Main>
    </>
  );
}

Estimates.propTypes = {
  estimates: PropTypes.array.isRequired
}

export default Estimates
