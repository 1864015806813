import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { useMutation } from '@apollo/client'
import SquarePaymentForm from 'react-square-payment-form'

import env from '../../../env'

import { ModalContext } from '~providers/ModalContext'
import { CREATE_MAILIN_TICKET } from '~mutations/tickets'

import { ReusableModal } from '../../Modals/Modal'
import LoadingContent from '../../Modals/Loading'
import Modal from '../../Shared/Modal'
import MailInForm from './Form'

const LoadingModal = styled(ReusableModal)`
  width: 100px !important;
  .modal-content {
    box-shadow: none;
    background: none;
    border: none;
  }
`

const MailInFormWrapper = ({ estimates }) => {
  const [unrecoverableError, setUnrecoverableError] = useState(null)
  const { modalOpen, toggleModal } = useContext(ModalContext)
  const [createTicket, { data, errors: apiErrors }] = useMutation(CREATE_MAILIN_TICKET)
  const [ccErrors, setCCErrors] = useState([])
  const initialValues = {
    serviceLevel: 'ups_dropoff',
    itemIds: [],
    returnAddressId: '',
    payment: {
      nonce: '',
      amountCents: 3500,
    },
    returnAddress: {
      mapboxId: null,
      name: '',
      street: '',
      addressLine2: '',
      city: '',
      stateCode: '',
      postalCode: '',
      phoneNumber: '',
      isResidential: true,
      hasDoorman: false,
      latitude: null,
      longitude: null
    }
  }

  const cardNonceResponseReceived = async (errors, nonce, cardData, buyerVerificationToken, { errors: formikErrors, values, setFieldValue, setSubmitting }) => {
    // see: https://github.com/square/react-square-payment-form/issues/58
    // ternary in case future patch fixes array of null issue
    const filteredErrors = errors ? errors.filter(e => ![undefined, null, ''].includes(e)) : []
    if (filteredErrors.length > 0) {
      setCCErrors(errors.filter(e => ![undefined, null, ''].includes(e)) || [])
      setSubmitting(false)
      toggleModal(false)
    } else if (Object.keys(formikErrors).length > 0) {
      // do nothing
    } else {
      setCCErrors([])
      values.payment.nonce = nonce
      await createMailinTicket(values, setSubmitting)
    }
    return null
  }

  const createMailinTicket = async (values, setSubmitting) => {
    try {
      await createTicket({ variables: values })
    } catch (e) {
      Sentry.captureException(e)
      setUnrecoverableError(true)
    }
    setSubmitting(false)
    toggleModal(false)
  }

  const validate = (values) => {
    const errors = {}
    if (values.itemIds.length < 1) {
      errors.itemIds = 'Please select at least one estimate to continue'
    }
    return errors
  }

  if (unrecoverableError) {
    return <Modal
      title="An error occured"
      body="There was a problem processing your request. Please try again later."
      hideCloseButton={false}
    />
  } else if (data) {
    navigate(`/account/tickets/${data.createMailinTicket.ticket.id}`, { state: { firstView: true } })
    return null
  } else {
    return (
      <Formik
        initialValues={initialValues}
        validate={validate}
        enableReinitialize={true}
        onSubmit={() => {}}
      >
        {(formikInstance) => (
          <SquarePaymentForm
            sandbox={env.SQUARE_SANDBOX}
            applicationId={env.SQUARE_APPLICATION_ID}
            locationId={env.SQUARE_LOCATION_ID}
            createVerificationDetails={() => {
              return {
                amount: formikInstance.values.payment.amountCents.toFixed(2).toString(),
                currencyCode: 'USD',
                intent: 'CHARGE',
                billingContact: {}
              }
            }}
            cardNonceResponseReceived={(errors, nonce, cardData, buyerVerificationToken) => {
              toggleModal(true)
              return cardNonceResponseReceived(errors, nonce, cardData, buyerVerificationToken, formikInstance)
            }}
          >
            <LoadingModal isOpen={modalOpen} toggleModal={toggleModal} showCloseButton={false} backdropClassName='modal-backdrop bg-clr-white'>
              <LoadingContent />
            </LoadingModal>
            <MailInForm estimates={estimates} createMailinTicket={createMailinTicket} ccErrors={ccErrors} apiErrors={apiErrors} />
          </SquarePaymentForm>
        )}
      </Formik>
    )
  }
}

MailInFormWrapper.propTypes = {
  estimates: PropTypes.array.isRequired
}

export default MailInFormWrapper
