import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import { getRealPostalFees } from '../Shared/Functions'
import { useFormikContext } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown, faClock, faCalendar } from '@fortawesome/free-solid-svg-icons'
import 'react-datepicker/dist/react-datepicker.css'
import './react-datepicker-override.scss'

import ZipCodeContext from '~components/EstimatesDelivery/ZipCodeContext'
import scssVars from '~components/scss-variables'

import { Label, Radio } from '../../layouts/form'
import { TextArea, Dropdown } from '../../Services/Payment/Components'
import { InlineError } from '../Shared/Components'

const LabelWrapper = styled.div`
  margin-bottom: 10px;
  ${Label} {
    margin-bottom: 0;
  }
  p {
    font-size: 14px;
    margin: 0 0 10px 20px;
  }
`

const LabelText = styled.span`
  color: ${scssVars.brightOrange};
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-left: 5px;
  text-transform: uppercase;
`

const PickupTime = styled.div`
  .timeIcon, .dateIcon {
    position: absolute;
    top: 10px;
    left: 5px;
    z-index: 2;
    font-size: 12px;
  }

  ${Dropdown} {
    padding-left: 20px;
  }

  ${TextArea} {
    min-height: 300px;
  }

  > div {
    margin-bottom: 13px;
  }
`

const AdditionalInstructions = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin: 10px 0;
`

const StyledHr = styled.hr`
  margin: 1.5rem 0;

  @media only screen and (max-width: ${scssVars.desktopMin}) {
    display: none;
  }
`

function Schedule() {
  const { zipCode } = useContext(ZipCodeContext)
  const { fees: [standard, onDemand] } = getRealPostalFees().find(value => value.id === zipCode) || { fees: [0, 0] }
  const { values, handleChange, setFieldValue, errors, touched, setTouched } = useFormikContext()

  const daysFromToday = (offset) => {
    const today = new Date()
    return today.setDate(today.getDate() + offset)
  }

  const isWeekday = date => date.getDay() !== 0 && date.getDay() !== 6

  const isDisabled = (desiredTime) => {
    const hourlyCutoff = 2 // if the user wants a pick up at 4PM, he needs to order by 2PM at the latest
    const today = new Date()
    today.setHours(0, 0, 0)
    const isToday = today.toString() === values.scheduledAt.toString()
    const currentTime = parseInt(new Date()
      .toLocaleString('en-US', { timeZone: 'America/New_York', hour12: false })
      .split(', ')
      .pop()
      .split(':')
      .shift(), 10)

    return isToday && (currentTime + hourlyCutoff) >= desiredTime
  }

  const changeServiceLevel = (e) => {
    const serviceLevel = e.target.value
    if (serviceLevel === 'standard' && values.scheduledAt) {
      const date = new Date(values.scheduledAt)
      date.setHours(0, 0, 0) // reset
      setFieldValue('scheduledAt', date)
    }
    setFieldValue('amount', serviceLevel === 'standard' ? standard : onDemand)
    handleChange(e)
  }

  const changePickupTime = (e) => {
    const hour = e.target.value
    const date = new Date(values.scheduledAt)
    setFieldValue('pickupTime', hour)
    date.setHours(hour, 0, 0)
    setFieldValue('scheduledAt', date)
  }

  useEffect(() => {
    setFieldValue('amount', values.serviceLevel === 'standard' ? standard : onDemand)
  }, [])

  return (
    <div>
      <div>
        <LabelWrapper>
          <Label>
            <Radio type="radio" name="serviceLevel" value="standard" id="standard" onChange={changeServiceLevel} />
            <LabelText>Standard Pick-Up - ${standard}</LabelText>
          </Label>
          {values.serviceLevel === 'standard' && (
            <>
              <p>No set time. Next business day pickup between 10 am and 7 pm.</p>
              <p>
                Free return delivery is included with this option.
                Includes package insurance up to $2,500.00 to and from Leather Spa.
                For more information on this service, please check out our help center article.
              </p>
            </>
          )}
        </LabelWrapper>
        <hr />
        <LabelWrapper>
          <Label>
            <Radio type="radio" name="serviceLevel" value="on_demand" id="on_demand" onChange={changeServiceLevel} />
            <LabelText>
              <FontAwesomeIcon icon={faCrown} /> On-Demand Pick-Up - ${onDemand}
            </LabelText>
          </Label>
          {values.serviceLevel === 'on_demand' && (
            <>
              <p>Schedule a pickup date and time that works for you. We will pick up your item(s) within the two-hour window you select.</p>
              <p>
                Free return delivery is included with this option.
                Includes package insurance up to $2,500.00 to and from Leather Spa.
                For more information on this service, please check out our help center article."
              </p>
            </>
          )}
        </LabelWrapper>
        <hr />
        <PickupTime>
          <div>
            <FontAwesomeIcon icon={faCalendar} className="dateIcon" />
            <DatePicker
              selected={values.scheduledAt}
              onChange={(date) => setFieldValue('scheduledAt', date)}
              onBlur={() => setTouched({ scheduledAt: true })}
              minDate={daysFromToday(1)}
              maxDate={daysFromToday(8)}
              filterDate={isWeekday}
              placeholderText="Pick-up date"
              name="scheduledAt"
              autoComplete="off"
              required
            />
            {errors.scheduledAt && touched.scheduledAt && <InlineError>{errors.scheduledAt}</InlineError>}
          </div>
          {values.serviceLevel === 'on_demand' && <div>
            <FontAwesomeIcon icon={faClock} className="timeIcon" />
            <Dropdown component="select" name="pickupTime" className="form-control" onChange={changePickupTime}>
              <option value="">Select a pick-up time</option>
              <option value="12" disabled={isDisabled(12)}>12pm to 2pm</option>
              <option value="14" disabled={isDisabled(14)}>2pm to 4pm</option>
              <option value="16" disabled={isDisabled(16)}>4pm to 6pm</option>
              <option value="18" disabled={isDisabled(18)}>6pm to 8pm</option>
            </Dropdown>
          </div>
          }
        </PickupTime>
        <div>
          <AdditionalInstructions>Additional instructions / comments:</AdditionalInstructions>
          <TextArea as="textarea" placeholder="Start typing here..." name="notes" value={values.notes} onChange={handleChange} />
        </div>
        <StyledHr />
      </div>
    </div>
  )
}

export default Schedule
