import React, { useState, useContext, useEffect } from 'react'
import PropType from 'prop-types'
import styled from 'styled-components'
import { Form, FormGroup, Input } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import ZipCodeContext from '~components/EstimatesDelivery/ZipCodeContext'
import scssVars from '~components/scss-variables'

import { getRealPostalFees } from './Shared/Functions'

const Main = styled.div`
  position: relative;
  background-color: ${scssVars.gray};
  width: 100%;
  height: 100%;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  input {
    border: 1px solid ${scssVars.beige};
    max-width: 150px;
    padding: 5px 10px;
    font-size: 15px;
    display: inline-block;
  }

  button {
    padding: 5px 10px !important;
  }

  form {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .form-group {
    display: flex;
    align-items: center;
  }
`

const Title = styled.h6`
  font-weight: 700;
  font-size: 18px;
`

const Regular = styled.p`
  font-size: 16px;
  span {
    color: ${scssVars.orange};
  }

  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin: 0px auto 25px auto;
    text-align: center;
    span {
      color: ${scssVars.deskBrown};
    }
  }
`

const Bolded = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  margin: 0 0 25px 0;
  font-weight: 800;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin: 0 auto 25px auto;
  }
`

const Hr = styled.hr`
  margin: 25px auto 25px auto;
  width: 80px;
  background-color: ${scssVars.brown};

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    display: none;
  }
`

const Close = styled.div`
  color: ${scssVars.beige};
  cursor: pointer;
  font-size: 25px;
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 1;
  transition: opacity 0.3s;

  :hover {
    opacity: 0.3;
  }

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    display: none;
  }
`

const Button = styled.button`
  width: auto !important;
  line-height: 1.5 !important;
`

function PickUpAvailabilityCheck ({ selectOption, option }) {
  const { zipCode, setZipCode } = useContext(ZipCodeContext)
  const postalCodes = getRealPostalFees()
  const [fee, setFee] = useState([])

  useEffect(() => { setZipCode('') }, [])

  const calculateDelivery = (e) => {
    e.preventDefault()
    const match = postalCodes.find(value => value.id === zipCode)

    if (match) {
      setFee(match.fees)
      selectOption('pickUp.success')
    } else {
      selectOption('pickUp.error')
    }
  }

  return (
    <Main>
      <Close>
        <FontAwesomeIcon icon={faTimes} onClick={() => {
          selectOption(option === 'pickUp.success' ? 'pickUp.success.closedForm' : 'none')
        }}/>
      </Close>
      {option === 'pickUp.form' && (
        <>
          <Title>Schedule a Pick-Up</Title>
          <div className="d-lg-none">
            <Regular>We offer pick-up services to most of Manhattan, much of Brooklyn, and Long Island City, Queens.</Regular>
          </div>
          <Regular>
            <span>Please enter your pick-up zip code to begin.</span>
          </Regular>
          <Form onSubmit={calculateDelivery} className="mx-auto" inline>
            <FormGroup>
              <Input type="number" autoFocus placeholder="Zip Code" pattern="[0-9]{5}" onChange={e => setZipCode(e.target.value)} value={zipCode} />
              <Button className="btn btn-primary" onClick={calculateDelivery} color="primary">Submit</Button>
            </FormGroup>
          </Form>
        </>
      )}
      {option === 'pickUp.error' && (
        <div>
          <Title className="text-primary">We’re Sorry!</Title>
          <Bolded>Leather Spa Pick-up service is not available in your area, but don’t worry, you can mail your item(s) to us for repair.</Bolded>
          <Hr />
          <Regular>Please select the "MAIL-IN FOR REPAIR" option to mail your item(s) to us.</Regular>
        </div>
      )}
      {option === 'pickUp.success' && (
        <div>
          <Title className="text-primary">Awesome!</Title>
          <Bolded>We offer pick-up service in your area. The estimated pick-up and return delivery service fee for your zip code is ${fee.join('-')}</Bolded>
        </div>
      )}
    </Main>
  )
}

PickUpAvailabilityCheck.propTypes = {
  selectOption: PropType.func.isRequired,
  option: PropType.string.isRequired
}

export default PickUpAvailabilityCheck
