import React, { useState } from 'react'
import PropType from 'prop-types'
import styled from 'styled-components'
import { Link }  from 'gatsby'
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import scssVars from '~components/scss-variables'

import { InlineError } from '../Shared/Components'
import { CheckBox } from '../../Services/Payment/Components'

const ShortWrapper = styled.div`
  flex-grow: 1;
`

const CaretWrapper = styled.div`
  position: relative;
`

const Estimate = styled.div`
  margin-top: 20px;
  height: 90px;
  width: 100%;
  background-color: ${scssVars.gray};
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    margin-top: 10px;
    height: 120px;
  }

  ${CheckBox} {
    background-color: ${scssVars.white};
    margin: 35px;
    cursor: pointer;

    @media only screen and (max-width: ${scssVars.tabletMax}) {
      margin-top: 50px;
    }
  }

  > div {
    position: relative;
    display: flex;

    @media only screen and (max-width: ${scssVars.tabletMax}) {
      flex-direction: column;
      padding-top: 23px;
    }
  }

  ${CaretWrapper} {
    width: 10%;
  }
`

const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1;
  width: 350px;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    width: auto;
  }
`

const EstimateNumber = styled.div`
  font-size: 24px;
  line-height: 1.4em;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 800;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    font-size: 18px;
  }
`

const CreatedAt = styled.div`
  font-size: 16px;
  letter-spacing: 0.4px;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    font-size: 14px;
  }
`

const Status = styled.div`
  flex-grow: 1;
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 20px 0 20px;

  span:first-child {
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    margin-right: 5px;
  }

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    display: none;
  }
`

const Price = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    flex-direction: row;
    justify-content: flex-start;
  }

  div:first-child {
    color: ${scssVars.orange};
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    text-align: right;
    line-height: 1em;

    :before {
      content: "$";
    }

    @media only screen and (max-width: ${scssVars.tabletMax}) {
      text-align: left;
    }
  }

  div:last-child {
    font-size: 16px;

    @media only screen and (max-width: ${scssVars.tabletMax}) {
      margin-left: 5px;
      color: ${scssVars.orange};
      line-height: 1.9;
      vertical-align: bottom;
    }
  }
`

const Caret = styled.div`
  width: 25px;
  height: 14px;
  overflow: hidden;
  margin: 38px;
  cursor: pointer;
  transition: transform 0.3s;
  transform: ${props => props.open ? 'rotate(180deg)' : 'rotate(0deg)'};

  div {
    position: relative;
    bottom: 16px;
    width: 25px;
    height: 25px;
    background-color: ${scssVars.beige};
    transform: rotate(45deg);
  }

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    position: absolute;
    margin: 0;
    top: 27px;
    width: 18px;
    height: 10px;
    right: 27px;
    transform: ${props => props.open ? 'rotate(0deg)' : 'rotate(-90deg)'};

    div {
      width: 16px;
      height: 16px;
      bottom: 10px;
    }
  }
`

const Details = styled.div`
  position: relative;
  padding: 0 0 0 70px;
  max-height: ${props => props.open ? '1000px' : '0px'};
  overflow: hidden;
  transition: max-height 0.3s;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    padding: 0;
    max-width: 90%;
    margin: 0 auto;
  }

  ${Caret} {
    cursor: default;
    position: absolute;
    top: -40px;
    left: 20px;

    @media only screen and (max-width: ${scssVars.tabletMax}) {
      left: 0;
      margin-left: 0;
    }

    div {
      background-color: ${scssVars.gray};
    }
  }

  a {
    max-width: 380px;

    @media only screen and (max-width: ${scssVars.tabletMax}) {
      max-width: none;
      margin: 0 auto 10px auto;

      border: 2px solid ${scssVars.orange};
      background-color: ${scssVars.white};
      color: ${scssVars.orange};
    }

    svg {
      margin-left: 10px;
    }
  }

  h6 {
    font-weight: 800;
    margin-bottom: 15px;
    margin-top: 35px;

    * {
      :after {
        content: "/";
        margin: 0 3px 0 3px;
      }

      :first-child:after {
        content: ":";
        margin-right: 5px;
      }

      :last-child:after {
        content: "";
      }
    }
  }

  ul {
    list-style: disc;
    padding-left: 17px;
    margin: 0 0 42px 0;

    @media only screen and (max-width: ${scssVars.tabletMax}) {
      margin-bottom: 20px;
    }
  }
`

const Option = styled.li`
  font-size: 16px;

  span:first-child {
    font-weight: 600;

    :after {
      content: ":";
      margin-right: 5px;
    }
  }
`

const ImageWrapper = styled(Col)`
  display: none;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    z-index: -1;
    display: flex;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`

const EstimateWrapper = styled(Col)`
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    ul {
      columns: 2;
    }
  }
`

const formatDate = (date) => {
  return new Date(date.replace(/-/g, '/')).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
}

const mergeByCategory = (acc, option) => {
  const category = option.category.name
  const index = acc.findIndex(value => value.category === category)

  index === -1
    ? acc.push({ category, options: [option.name] })
    : acc[index].options.push(option.name)

  return acc
}

function SingleEstimate ({ estimate }) {
  const [open, toggle] = useState(false)
  const options = estimate.options ? estimate.options.reduce(mergeByCategory, []) : []

  const validate = value => !value ? <InlineError>This field is required</InlineError> : undefined

  return (
    <>
      <Estimate>
        <CheckBox type="checkbox" id={estimate.id} name="itemIds" value={estimate.id} validate={validate} />
        <ShortWrapper>
          <Label htmlFor={estimate.id}>
            <EstimateNumber>Estimate #{estimate.confirmationCode}</EstimateNumber>
            <CreatedAt>{formatDate(estimate.createdAt)}</CreatedAt>
          </Label>
          <Status>
            <div>
              <span>Status:</span>
              <span>{estimate.status || 'Item Not Yet Received'}</span>
            </div>
          </Status>
          <Price>
            <div>{estimate.formattedPrice}</div>
            <div>Estimated price</div>
          </Price>
        </ShortWrapper>
        <CaretWrapper onClick={() => toggle(!open)} aria-expanded={open} role="button">
          <Caret open={open}>
            <div></div>
          </Caret>
        </CaretWrapper>
      </Estimate>
      <Details open={open}>
        <Caret>
          <div>
            <div></div>
          </div>
        </Caret>
        <Row>
          <ImageWrapper>
            <img src={estimate.photoUrl} />
          </ImageWrapper>
          <EstimateWrapper lg={10}>
            <h6>
              <span>{estimate.apparel.name}</span>
              <span>{estimate.brand}</span>
              <span>{estimate.color}</span>
              <span>{estimate.material}</span>
            </h6>
            <ul>
              {options.map((option, i) =>
                <Option key={i}>
                  <span>{option.category}</span>
                  <span>{option.options.join(', ')}</span>
                </Option>)
              }
            </ul>
            <Link className="btn btn-primary" to={`/account/estimates?id=${estimate.id}`}>
              View Detailed Estimate
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </Link>
          </EstimateWrapper>
        </Row>
      </Details>
    </>
  )
}

SingleEstimate.propTypes = {
  estimate: PropType.object.isRequired
}

export default SingleEstimate
