import React from 'react'

import { InlineError } from './Components'
import { getPostalCodes } from '~queries/postalCode'

export const required = value => !value ? <InlineError>This field is required</InlineError> : undefined

export const createVerificationDetails = ({ values }) => {
  return {
    amount: values.amount.toFixed(2).toString(),
    currencyCode: 'USD',
    intent: 'CHARGE',
    billingContact: {}
  }
}

export function getRealPostalFees () {
  const postalCodes = getPostalCodes()
  return postalCodes.map(value => Object.assign({}, value, { fees: [value.fees[1], 45] }))
}

export function formatDateAndTimeToISO8601 (date, hours) {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hour = hours.toString().padStart(2, '0')
  const ISODate = `${year}-${month}-${day}T${hour}:00:00.000Z`

  return ISODate
}
