import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import * as Sentry from '@sentry/react'
import SquarePaymentForm from 'react-square-payment-form'

import { CREATE_PICKUP_TICKET } from '~mutations/tickets'
import { ModalContext } from '~providers/ModalContext'
import { getPostalCodes } from '~queries/postalCode'

import env from '../../../env'
import { ReusableModal } from '../../Modals/Modal'
import LoadingContent from '../../Modals/Loading'

import PickUpForm from './Form'
import { createVerificationDetails } from '../Shared/Functions'
import { InlineError } from '../Shared/Components'
import Modal from '../../Shared/Modal'

const LoadingModal = styled(ReusableModal)`
  width: 100px !important;
  .modal-content {
    box-shadow: none;
    background: none;
    border: none;
  }
`

function prepPayload (values, nonce, buyerVerificationToken) {
  return {
    variables: {
      serviceLevel: values.serviceLevel,
      scheduledAt: values.scheduledAt,
      nonce,
      token: buyerVerificationToken,
      amount: parseFloat(values.amount) * 100,
      fromAddressId: values.fromAddressId,
      fromAddress: values.fromAddress,
      returnAddressId: values.returnAddressId,
      returnAddress: values.returnAddress,
      itemIds: values.itemIds
    }
  }
}

const PickUpFormWrapper = ({ estimates }) => {
  const [unrecoverableError, setUnrecoverableError] = useState(null)
  const { modalOpen, toggleModal } = useContext(ModalContext)
  const [ccErrors, setCCErrors] = useState([])
  const [createTicket, { data, errors: apiErrors }] = useMutation(CREATE_PICKUP_TICKET)
  const postalCodes = getPostalCodes()

  const validate = (values) => {
    const errors = {}
    if (values.itemIds.length < 1) {
      errors.itemIds = 'Please select at least one estimate to continue'
    }
    if (values.fromAddress.postalCode && !values.fromAddressId && !postalCodes.map(p => p.id).includes(values.fromAddress?.postalCode)) {
      errors.fromPostalCode = <InlineError>Postal Code must be within pickup range</InlineError>
    }
    if (values.returnAddress.postalCode && !values.returnAddressId && !postalCodes.map(p => p.id).includes(values.returnAddress?.postalCode)) {
      errors.returnPostalCode = <InlineError>Postal Code must be within pickup range</InlineError>
    }
    if (!values.scheduledAt) {
      errors.scheduledAt = <InlineError>This field is required</InlineError>
    }
    return errors
  }

  const initialValues = {
    itemIds: [],
    // TODO: change nonce/token variable bindings to gql object.
    nonce: '',
    token: '',
    amount: 0,

    serviceLevel: 'standard',
    scheduledAt: '',
    pickupTime: '',
    notes: '',

    sameAddress: true,

    fromAddressId: '',
    fromAddress: {
      name: '',
      street: '',
      addressLine2: '',
      city: '',
      stateCode: '',
      postalCode: '',
      phoneNumber: '',
      isResidential: true,
      hasDoorman: false
    },
    returnAddressId: '',
    returnAddress: {
      name: '',
      street: '',
      addressLine2: '',
      city: '',
      stateCode: '',
      postalCode: '',
      phoneNumber: '',
      isResidential: true,
      hasDoorman: false
    }
  }

  const cardNonceResponseReceived = async (errors, nonce, cardData, buyerVerificationToken, { errors: formikErrors, values, setSubmitting }) => {
    // see: https://github.com/square/react-square-payment-form/issues/58
    // ternary in case future patch fixes array of null issue
    const filteredErrors = errors ? errors.filter(e => ![undefined, null, ''].includes(e)) : []
    if (filteredErrors.length > 0) {
      setCCErrors(errors.filter(e => ![undefined, null, ''].includes(e)) || [])
    } else if (Object.keys(formikErrors).length > 0) {
      // do nothing
    } else {
      setCCErrors([])
      const payload = prepPayload(values, nonce, buyerVerificationToken)
      try {
        await createTicket(payload)
      } catch (e) {
        Sentry.captureException(e)
        setUnrecoverableError(true)
      }
    }
    setSubmitting(false)
    toggleModal(false)
  }

  if (unrecoverableError) {
    return <Modal
      title="An error occured"
      body="There was a problem processing your request. Please try again later."
      hideCloseButton={false}
    />
  } else if (data) {
    navigate(`/account/tickets/${data.createPickupTicket.ticket.id}`, { state: { firstView: true } })
    return null
  } else {
    return (
      <Formik
        initialValues={initialValues}
        validate={validate}
        enableReinitialize={true}
        onSubmit={() => {}}
      >
        {(formikInstance) => (
          <SquarePaymentForm
            sandbox={env.SQUARE_SANDBOX}
            applicationId={env.SQUARE_APPLICATION_ID}
            locationId={env.SQUARE_LOCATION_ID}
            createVerificationDetails={() => createVerificationDetails(formikInstance)}
            cardNonceResponseReceived={(errors, nonce, cardData, buyerVerificationToken) => {
              toggleModal(true)
              return cardNonceResponseReceived(errors, nonce, cardData, buyerVerificationToken, formikInstance)
            }}
          >
            <LoadingModal isOpen={modalOpen} toggleModal={toggleModal} showCloseButton={false} backdropClassName='modal-backdrop bg-clr-white'>
              <LoadingContent />
            </LoadingModal>
            <PickUpForm estimates={estimates} ccErrors={ccErrors} apiErrors={apiErrors} postalCodes={postalCodes}/>
          </SquarePaymentForm>
        )}
      </Formik>
    )
  }
}

PickUpFormWrapper.propTypes = {
  estimates: PropTypes.array.isRequired
}

export default PickUpFormWrapper
