import styled from 'styled-components'

import scssVars from '~components/scss-variables'

export const Lead = styled.h2`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${scssVars.darkBrown};
  margin-bottom: 1rem;
  letter-spacing: 0;
`
