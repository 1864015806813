import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { Spinner } from 'reactstrap'
import { useMutation } from '@apollo/client'
import { Form, Formik } from 'formik'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

import { CREATE_DROPOFF_TICKET } from '~mutations/tickets'
import Estimates from '~components/EstimatesDelivery/Shared/Estimates'
import { BlockError } from '~components/EstimatesDelivery/Shared/Components'
import { ModalContext } from '~providers/ModalContext'
import Modal from '../../Shared/Modal'
import { ReusableModal } from '../../Modals/Modal'
import LoadingContent from '../../Modals/Loading'

const LoadingModal = styled(ReusableModal)`
  width: 100px !important;
  .modal-content {
    box-shadow: none;
    background: none;
    border: none;
  }
`

const Button = styled.button`
  && {
    display: block;
    max-width: 380px;
    width: 90%;
    margin: 40px auto 40px auto;
  }
`

const DropOffForm = ({ estimates }) => {
  const [unrecoverableError, setUnrecoverableError] = useState(null)
  const { modalOpen, toggleModal } = useContext(ModalContext)
  const [createTicket, { data: ticket, errors: apiErrors }] = useMutation(CREATE_DROPOFF_TICKET)

  const validate = (values) => {
    const errors = {}
    if (values.itemIds.length < 1) {
      errors.itemIds = 'Please select at least one estimate to continue'
    }
    return errors
  }

  const initialValues = {
    itemIds: []
  }

  const onSubmit = async (values) => {
    try {
      toggleModal(true)
      await createTicket({ variables: values })
    } catch (e) {
      Sentry.captureException(e)
      setUnrecoverableError(true)
    }
    toggleModal(false)
  }

  if (unrecoverableError) {
    return <Modal
      title="An error occured"
      body="There was a problem processing your request. Please try again later."
      hideCloseButton={false}
    />
  } else if (ticket) {
    navigate(`/account/tickets/${ticket.createRepairTicket.ticket.id}`, { state: { firstView: true } })
    return null
  } else {
    return (
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ errors, touched, isSubmitting }) => (
          <>
            <LoadingModal isOpen={modalOpen} toggleModal={toggleModal} showCloseButton={false} backdropClassName='modal-backdrop bg-clr-white'>
              <LoadingContent />
            </LoadingModal>
            <Form>
              <Estimates estimates={estimates} />
              {apiErrors && apiErrors.map(error => <BlockError key={error}>{error}</BlockError>)}
              <Button className="btn btn-primary">
                {isSubmitting ? <Spinner color="light" /> : (
                  <>
                    <FontAwesomeIcon icon={faPrint} />&nbsp;Print Your Packing Slip
                </>
                )}
              </Button>
            </Form>
          </>
        )}
      </Formik>
    )
  }
}

DropOffForm.propTypes = {
  estimates: PropTypes.array.isRequired
}

export default DropOffForm
