import { Field } from 'formik'
import styled from 'styled-components'
import scssVars from '../../scss-variables'

export const Checkbox = styled(Field)`
  border: 1px solid #BCB5A4;
  margin: 1px;
  width: 15px;
  height: 15px;

  &:checked::after {
    content: '✔\fe0e';
    font-size: 15px;
    top: -5px;
    color: ${scssVars.brightOrange};
  }
`

export const Radio = styled(Field)`
  background-color: #fafafa;
  border: 1px solid #cacece;
  padding: 7px !important;
  border-radius: 50px;
  margin: 3px 0px 6px 0;

  &:checked:after {
    content: ' ';
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: ${scssVars.brightOrange};
    text-shadow: 0px;
    font-size: 32px;
    top: 2px;
    left: 2px;
  }
`

export const Label = styled.label`
  display: flex;
  flex-direction: row;

  ${Radio}, ${Checkbox} {
    outline: 0;
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05),inset 0px 1px 3px rgba(0,0,0,0.1);
    min-width: 15px;
    max-height: 15px;
    &:checked {
      background-color: #e9ecee;
      color: ${scssVars.brightOrange};
      box-shadow: 0 1px 2px rgba(0,0,0,0.05),inset 0px 1px 3px rgba(0,0,0,0.1);
      border: 1px solid #adb8c0;
    }
    &:active, &:checked:active {
      box-shadow: 0 1px 2px rgba(0,0,0,0.05),inset 0px 1px 3px rgba(0,0,0,0.1);
    }
    &:checked:after {
      position: absolute;
    }
  }
`
