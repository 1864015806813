import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Spinner } from 'reactstrap'
import { Link } from 'gatsby'
import { Form, useFormikContext } from 'formik'
import SquareContext from 'react-square-payment-form/lib/components/Context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faBoxOpen, faReceipt, faCheck } from '@fortawesome/free-solid-svg-icons'
import { faUps } from '@fortawesome/free-brands-svg-icons'

import scssVars from '~components/scss-variables'

import { Label, Radio, Checkbox } from '../../layouts/form'
import Estimates from '../Shared/Estimates'
import UsedAddressesDropdown from '../Shared/UsedAddressesDropdown'
import { Main, Button, BlockError } from '../Shared/Components'
import { SectionTitle } from '../../Services/Payment/Components'
import AddressForm from '../Shared/NewAddressForm'
import WhatsNextSection from '../PickUpForm/About'
import CC from '../Shared/CC'

const Agreement = styled.p`
  font-size: 16px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  max-width: 95%;
  position: relative;
  margin: 0 auto;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    max-width: 1280px;
  }
`

const Controls = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: ${scssVars.tabletMax}) {
    flex-direction: column;
    margin-bottom: 15px;
  }

  ${Button} {
      margin: 15px auto 50px auto;
  }
`

const LabelWrapper = styled.div`
  margin-bottom: 10px;
  ${Label} {
    margin-bottom: 0;
  }
  p {
    font-size: 14px;
    margin: 0 0 10px 20px;
  }
  a {
    font-family: inherit;
    font-weight: 600;
  }
`

const LabelText = styled.span`
  color: ${scssVars.brightOrange};
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-left: 8px;
  text-transform: uppercase;
  .svg-inline--fa {
    margin-right: 3px;
  }

`

const ExplanatoryText = styled.div`
  margin-top: -5px;
  margin-left: 20px;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    p {
      font-size: 14px !important;
    }
  }
`

const AdditionalInsuranceInput = ({ serviceLevel }) => {
  const { values, setFieldValue, handleChange, touched } = useFormikContext()

  const setInsurance = (e) => {
    const isInsured = e.target.checked
    setFieldValue('isInsured', isInsured)
    if (isInsured) setFieldValue('payment[amountCents]', values.payment['amountCents'] += 1000)
    handleChange(e)
  }

  return values.serviceLevel === serviceLevel && (
    <LabelWrapper style={{ paddingLeft: '1.25rem' }}>
      <Label>
        <Checkbox type="checkbox" name="isInsured" value="true" checked={values.isInsured} onChange={setInsurance} />
        <LabelText>
          <FontAwesomeIcon icon={faUps} style={{ fontSize: 20 }}/> Additional Insurance (up to $5,000.00) - $10
        </LabelText>
      </Label>
      <p>
        Insurance for your package up to $5,000.00 to and from Leather Spa.
      </p>
    </LabelWrapper>
  )
}

const MailInForm = ({ estimates, createMailinTicket, ccErrors, apiErrors }) => {
  const { values, errors, isSubmitting, setSubmitting, setFieldValue, handleChange, touched } = useFormikContext()
  const squareContext = useContext(SquareContext)

  const onClick = (e) => {
    if (values.serviceLevel === 'standard') {
      return createMailinTicket(values, setSubmitting)
    } else {
      return squareContext.onCreateNonce(e)
    }
  }

  const changeServiceLevel = (e) => {
    const serviceLevel = e.target.value
    let amountCents
    if (serviceLevel === 'ups_dropoff') {
      amountCents = 3500
    } else if (serviceLevel === 'shipping_label') {
      amountCents = 2500
    } else {
      amountCents = 0
    }
    setFieldValue('payment[amountCents]', amountCents)
    setFieldValue('isInsured', false)
    handleChange(e)
  }


  return (
    <Form>
      <Estimates estimates={estimates} />
      <Main>
        <div>
          <section>
            <SectionTitle>1. Return Address</SectionTitle>
            <p>Where should we send your item(s) after your repairs are completed?</p>
            <UsedAddressesDropdown fieldPrefix="returnAddress" />
            {/* TODO: get rid of setFieldValue prop */}
            {!values.returnAddressId && <AddressForm fieldPrefix="returnAddress" setFieldValue={setFieldValue} />}
          </section>
        </div>
        <div>
          <section>
            <SectionTitle>2. Shipping Mode To Leather Spa</SectionTitle>
            <p>Please choose one of the following options:</p>
            <LabelWrapper>
              <Label>
                <Radio type="radio" name="serviceLevel" value="ups_dropoff" onChange={changeServiceLevel} />
                <LabelText>
                  <FontAwesomeIcon icon={faUps} style={{ fontSize: 20 }}/> Drop Off At The UPS Store® - $35
                </LabelText>
              </Label>
            </LabelWrapper>
            {values.serviceLevel === 'ups_dropoff' && (
              <ExplanatoryText>
                <p>
                  Simply bring to any UPS Store within the continental US.
                  They will handle the packing, labeling, and shipping of your repair order.
                  We'll email you a drop off confirmation code and instructions.
                  Print out or show it on your smartphone to a store associate at The UPS Store.
                </p>
                <p>
                  Free return shipping is included with this option.
                  Includes package insurance up to $2,500.00 to and from Leather Spa.
                  For more information on this service, please check out our <a className="text-link" href="https://leatherspa.zendesk.com/hc/en-us/articles/360057155573-The-UPS-Store-Drop-Off-Program" target="_blank" rel="noreferrer noopener">help center</a> article.
                </p>
                <p>Printer and packaging not required.</p>
              </ExplanatoryText>
            )}
            <AdditionalInsuranceInput serviceLevel="ups_dropoff" />
            <hr />
            <LabelWrapper>
              <Label>
                <Radio type="radio" name="serviceLevel" value="shipping_label" onChange={changeServiceLevel} />
                <LabelText>
                  <FontAwesomeIcon icon={faReceipt} /> Buy A Shipping Label (BETA) - $25
                </LabelText>
              </Label>
            </LabelWrapper>
            {values.serviceLevel === 'shipping_label' && (
              <ExplanatoryText>
                <p>Create and print a shipping label on our platform and use your own packaging. Your shipping label will be emailed to you instantly.</p>
                <p>Free return shipping is included with this option. Includes package insurance up to $2,500.00 to and from Leather Spa. For more information on this service, please check out our <a className="text-link" href="https://leatherspa.zendesk.com/hc/en-us" target="_blank" rel="noreferrer noopener">help center</a> article.</p>
                <p>Printer and packaging required.</p>
              </ExplanatoryText>
            )}
            <AdditionalInsuranceInput serviceLevel="shipping_label" />
            <hr />
            <LabelWrapper>
              <Label>
                <Radio type="radio" name="serviceLevel" value="standard" onChange={changeServiceLevel} />
                <LabelText>
                  <FontAwesomeIcon icon={faBoxOpen} size="1x" /> Ship It Myself
                </LabelText>
              </Label>
              {values.serviceLevel === 'standard' && (
                <ExplanatoryText>
                  <p>Organize shipment yourself.</p>
                  <p>You will use your own carrier, label, and packaging and pay for return shipping.</p>
                </ExplanatoryText>
              )}
            </LabelWrapper>
            <hr />
          </section>
          <section className={values.serviceLevel === 'standard' ? 'd-none' : ''}>
            <SectionTitle>3. Credit Card Information</SectionTitle>
            <p>You will only be charged for the shipping service now. We will send you a payment link for your repairs once your items have been received and evaluated.</p>
            <CC errors={ccErrors} />
          </section>
        </div>
      </Main>
      <Agreement className="pt-3 text-lg-center">
        <FontAwesomeIcon icon={faCheck} style={{ color: scssVars.brightOrange, margin: '0 5px 1px 0' }} />
        By submitting this form, I confirm that I fully understand and accept the terms in the Leather Spa <a href="/operational-policies" target="_blank"> Mail-In Policies</a>
      </Agreement>
      {(errors && Object.values(errors).length && touched && Object.values(touched).length)
        ? <BlockError>Your submission contains errors, please check that everything has been filled correctly.</BlockError>
        : null
      }
      {apiErrors && apiErrors.map(error => <BlockError key={error}>{error}</BlockError>)}
      <Controls>
        <Button type="submit" className="btn btn-primary" disabled={isSubmitting || errors.length > 0} onClick={onClick}>
          {isSubmitting || errors.length > 0 ? <Spinner color="light" /> : (
            <>
              <FontAwesomeIcon icon={faPrint} />{values.serviceLevel === "standard" ? "Print Your Packing Slip" : "Pay And Print Your Packing Slip"}
            </>
          )}
        </Button>
      </Controls>
      <WhatsNextSection>
        <p>Once we receive and process your item(s), we will contact you with a final quote and payment instructions. Please allow us up to three business days for processing/contact.</p>
        <p>If you have any questions regarding our mail-in service, please email us or check out our Operational and Mail-in Policies.</p>
        <p>
          For more information on our operating policies, please visit&nbsp;
          <Link className="text-link" to="/operational-policies">leatherspa.com/customer-service</Link>.
        </p>
      </WhatsNextSection>
    </Form>
  )
}

MailInForm.propTypes = {
  estimates: PropTypes.array.isRequired,
  createMailinTicket: PropTypes.func.isRequired,
  ccErrors: PropTypes.array,
  apiErrors: PropTypes.array
}

export default MailInForm
