import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Spinner } from 'reactstrap'
import { Form, useFormikContext } from 'formik'
import SquareContext from 'react-square-payment-form/lib/components/Context'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import scssVars from '~components/scss-variables'

import { SectionTitle, CheckBox } from '../../Services/Payment/Components'
import { Main, Hr, BlockError } from '../Shared/Components'
import CC from '../Shared/CC'
import Schedule from './Schedule'
import UsedAddressesDropdown from '../Shared/UsedAddressesDropdown'
import AddressForm from '../Shared/NewAddressForm'
import Estimates from '../Shared/Estimates'
import WhatsNextSection from './About'

const Button = styled.button`
  && {
    display: block;
    margin: 15px auto 50px auto;
    width: 450px;
    max-width: 95%;

    svg {
      margin-right: 10px;
    }
  }
`

const Label = styled.label`
  font-family: 'Montserrat', sans-serif;
  margin-left: 10px;
  vertical-align: top;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;

  span {
    margin-left: 5px;
    vertical-align: top;
    line-height: 1.5;
  }
`

const Agreement = styled.p`
  font-size: 16px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  max-width: 95%;
  position: relative;
  margin: 0 auto;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    max-width: 1280px;
  }
`

const PickUpForm = ({ estimates, ccErrors, apiErrors, postalCodes }) => {
  const { setFieldValue, values, errors, isSubmitting, touched } = useFormikContext()
  const squareContext = useContext(SquareContext)

  const changeSameAddress = (e) => {
    const checked = !values.sameAddress
    setFieldValue('sameAddress', checked)
    setFieldValue('returnAddressId', checked ? values.fromAddressId : '')
  }
  return (
    <Form>
      <Estimates estimates={estimates} />
      <Main>
        <div>
          <section>
            <SectionTitle>1. Pick-Up Address</SectionTitle>
            <p>Where do you want your items to be picked-up?</p>
            <UsedAddressesDropdown fieldPrefix="fromAddress" formType="pick_up" postalCodes={postalCodes} />
            {!values.fromAddressId && <AddressForm fieldPrefix="fromAddress" />}
            {errors.fromPostalCode}
            <SectionTitle>RETURN DELIVERY ADDRESS</SectionTitle>
            <p>Where should we deliver your item(s) after your repairs are completed?</p>
            <Label>
              <CheckBox type="checkbox" name="sameAddress" checked={values.sameAddress} onChange={changeSameAddress} />
              <span>Same as pick-up address.</span>
            </Label>
            {!values.sameAddress && (
              <>
                <UsedAddressesDropdown fieldPrefix="returnAddress" formType="pick_up" postalCodes={postalCodes} />
                {!values.returnAddressId && <AddressForm fieldPrefix="returnAddress" />}
                {errors.returnPostalCode}
              </>
            )}
          </section>
        </div>
        <div>
          <section>
            <SectionTitle>2. SCHEDULE A PICK-UP</SectionTitle>
            <p>We only accept pick-up requests up to a week in advance.</p>
            <Schedule />
          </section>
          <Hr />
          <section>
            <SectionTitle>3. Credit Card Information</SectionTitle>
            <p>
              You will only be charged for the pickup service now.
              We will send you a payment link for your repair quote once your items have been received and evaluated by our technicians.
            </p>
            <CC errors={ccErrors} />
          </section>
          <Hr />
        </div>
      </Main>
      <Agreement className="pt-3 text-lg-center">
        <FontAwesomeIcon icon={faCheck} style={{ color: scssVars.brightOrange, margin: '0 5px 1px 0' }} />
        By submitting this form, I confirm that I fully understand and accept the terms in the Leather Spa <a href="/operational-policies" target="_blank"> Pickup Policies</a>
      </Agreement>
      {(errors && Object.values(errors).length && touched && Object.values(touched).length)
        ? <BlockError>Your submission contains errors, please check that everything has been filled correctly.</BlockError>
        : null
      }
      {apiErrors && apiErrors.map(error => <BlockError key={error}>{error}</BlockError>)}
      <Button
        type="submit"
        className="btn btn-primary"
        disabled={isSubmitting || errors.length > 0}
        onClick={squareContext.onCreateNonce}
      >
        {isSubmitting || errors.length > 0 ? <Spinner color="light" /> : (
          <>
            PAY AND SCHEDULE PICKUP
          </>
        )}
      </Button>
      <WhatsNextSection>
        <p>The day of your pickup, we will notify you via email or text message that our specialist is on the way to collect your order.</p>
        <p>Once we receive your order please give us 2 to 3 business days to process and evaluate your item(s).</p>
        <p>This service is limited to shoes, handbags, garments, and small leather goods (belts/wallets/etc); 10 items or less. For larger orders or items/pieces (furniture) please contact us <a href="mailto:repairs@leatherspa.com">repairs@leatherspa.com</a> to schedule pick-up arrangements.</p>
        <p>If you have any questions regarding our pick-up service, please <a href="mailto:repairs@leatherspa.com">email us</a> or check out our <a href="/operational-policies">Operational and Pickup Policies</a>.</p>
      </WhatsNextSection>
    </Form>
  )
}

PickUpForm.propTypes = {
  estimates: PropTypes.array.isRequired,
  ccErrors: PropTypes.array,
  apiErrors: PropTypes.array,
  postalCodes: PropTypes.array.isRequired
}

export default PickUpForm
