import React from 'react'
import PropType from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

import scssVars from '~components/scss-variables'
import { Picture } from '~components/Services/Intro'
import PickUpAvailabilityCheck from '~components/EstimatesDelivery/PickUpAvailabilityCheck'

const mailIn = 'https://leatherspa.imgix.net/LS_Estimate-Mobile_Box.jpg'
const van = 'https://leatherspa.imgix.net/LS_Estimate-Mobile_Van.jpg'
const dropOff = 'https://leatherspa.imgix.net/LS_Estimate-Mobile_Map.jpg'

const Regular = styled.p`
  font-size: 20px;
`

const Title = styled.h5`
  font-weight: 500;
  font-size: 0.88rem;
  margin-bottom: 0;
`

const Image = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;

  img {
    max-height: 70px;

    @media only screen and (min-width: ${scssVars.tabletMin}) {
      max-height: 125px;
    }
    @media only screen and (min-width: ${scssVars.desktopMin}) {
      max-height: none;
    }
  }
`

const Main = styled.div`
  > h3 {
    padding: 0 15px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: ${scssVars.beige};
    display: flex;
    position: relative;
    z-index: 2;

    li {
      position: relative;
      cursor: pointer;
      width: 33.3%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: inherit;
      color: ${scssVars.darkBrown};
      transition: color 0.3s, background-color 0.3s;
      padding: 4vw;

      :hover, &.selected {
        background-color: ${scssVars.brown};
        color: ${scssVars.white};
      }

      svg {
        display: block;
        background-color: ${scssVars.white};
        padding: 5px;
        width: 40px !important;
        height: 40px;
        border-radius: 100%;
        position: absolute;
        font-size: 40px;
        color: ${scssVars.orange};
        bottom: -20px;
        left: calc(50% - 20px);
        opacity: 0;
        transition: opacity 0.3s;

        &.selected {
          opacity: 1;
        }
      }
    }
  }
`

const Details = styled.div`
  position: relative;
  background-color: ${scssVars.gray};
  color: ${scssVars.darkBrown};
  padding: 35px 0 30px 0;
  min-height: 11em;

  > * {
    max-width: 90%;
    margin: 0 auto;
  }

  ${Title} {
    margin-top: 0;
  }
`

const Triangle = styled.div`
  position: absolute;
  width: 27px;
  height: 27px;
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  background-color: ${scssVars.white};
  bottom: -13.5px;
  transition: left 0.3s, opacity 0.3s;
  opacity: 0;

  &.mailIn {
    left: calc(16.7% - 13.5px);
    opacity: 1;
  }

  &.dropOff {
    left: calc(83.35% - 13.5px);
    opacity: 1;
  }

  &[class*="pickUp.success"] {
    left: calc(50% - 13.5px);
    opacity: 1;
  }
`

function DeliveryOptions ({ option, selectOption }) {
  return (
    <Main>
      <h3 className="mt-4 text-center">Choose a Shipping Mode:</h3>
      <ul>
        <li className={option === 'mailIn' ? 'selected' : ''} onClick={() => selectOption('mailIn')}>
          <Image>
            <Picture mobileSrc={mailIn} src={mailIn} />
          </Image>
          <Title>Mail-In</Title>
          <FontAwesomeIcon className={option === 'mailIn' ? 'selected' : ''} icon={faCheck} />
        </li>
        <li className={['pickUp.form', 'pickUp.error', 'pickUp.success'].includes(option) ? 'selected' : ''} onClick={() => selectOption('pickUp.form')}>
          <Image className="van">
            <Picture mobileSrc={van} src={van} />
          </Image>
          <Title>Pick-Up</Title>
          <FontAwesomeIcon className={option === 'pickUp.success' ? 'selected' : ''} icon={faCheck} />
          <FontAwesomeIcon className={option === 'pickUp.error' ? 'selected' : ''} icon={faTimes} />
        </li>
        <li className={option === 'dropOff' ? 'selected' : ''} onClick={() => selectOption('dropOff')}>
          <Image>
            <Picture mobileSrc={dropOff} src={dropOff} />
          </Image>
          <Title>Drop-Off</Title>
          <FontAwesomeIcon className={option === 'dropOff' ? 'selected' : ''} icon={faCheck} />
        </li>
      </ul>
      <Details hidden={option === 'none'}>
        {['pickUp.form', 'pickUp.error', 'pickUp.success'].includes(option) && <PickUpAvailabilityCheck selectOption={selectOption} option={option} />}
        <Title>
          {option === 'mailIn' && 'Mail-In For Repair'}
          {/* Schedule A Pick-Up */}
          {option === 'dropOff' && 'Drop-Off For Repair' }
        </Title>
        <Regular>
          {option === 'mailIn' && 'We accept mail-in repairs worldwide and even from our own backyard. Simply send us your items if you can’t bring them in-store.'}
          {/* We offer pick-up services to most of Manhattan, much of Brooklyn, and Long Island City, Queens. */}
          {option === 'dropOff' && 'Experience our expertise in-person at one of our breathtaking Manhattan locations.'}
        </Regular>
        <Triangle className={option} />
      </Details>
    </Main>
  )
}

DeliveryOptions.propTypes = {
  option: PropType.string.isRequired,
  selectOption: PropType.func.isRequired
}

export default DeliveryOptions
